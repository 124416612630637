import React from 'react';
import { navigate } from 'gatsby'
import { Box, Card, CardHeader, CardBody, Heading,  Image, Paragraph } from 'grommet';

export default function SidebarCTA() {

  const onClickButton_CTAHome = () => {
    navigate ('/compliance-testing-analyst')
  }
  return (
    <Box pad={{top: '10px'}} direction="column" justify="between" gap="medium" style={{maxWidth: '320px', width: '100%', height: 'max-content'}}>
      <Card background="light-1" style={{borderRadius: '.5em', overflow: 'visible', boxShadow: 'none'}}>
      <CardHeader gap="small" justify="start">
        <Box style={{border: '4px solid #F8F8F8', borderRadius: '50%', margin: '-30px 0 0 20px', maxWidth: '110px', width: '100%'}}><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/xVwXW6l076OeR8sc96Y4Q/fc2672c2718cc00814f90be1657ce4d1/dsa-avatar-2.png" style={{borderRadius: '50%'}} onClick={onClickButton_CTAHome} /></Box>
        <Heading level={4} style={{paddingTop: '10px'}}>Compliance Testing Analyst</Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Paragraph style={{fontSize: '14px', lineHeight: '1.4', marginBottom: '20px'}}>A compliance testing analyst evaluates adherence to legal and regulatory requirements. They test, review documentation, report results, and work to mitigate potential risks associated with non-compliance.</Paragraph>
          <Heading level={5} style={{marginBottom: '10px'}}>Challenges</Heading>
          <ul style={{fontSize: '14px', lineHeight: '1.5', marginBottom: '20px', padding: '0 0 0 15px'}}>
            <li>Dealing with complex requirements that can be difficult to interpret and apply</li>
            <li>Allocating resources and balancing the demands of compliance testing with other organizational priorities</li>
            <li>Keeping up with changing requirements and ensuring testing procedures are effective</li>
          </ul>
        </CardBody>
      </Card>
      <Paragraph style={{fontSize: '18px', lineHeight: '1.5', fontWeight: '700', padding: '0 20px'}}>To learn more, reach out to your PwC Sponsor.</Paragraph>
    </Box>
  );
}